import * as React from 'react';
import { Button, Typography, Grid, Modal, Box } from '@mui/material';

export default function WordCard({ nepaliWord, englishWord, index, setIndex, numberOfWords, setShowWordContainer, showWordContainer }) {
    const nextText = 'Next ->';
    const prevText = '<- Previous';

    const handleClick = (buttonClick) => {
        if (buttonClick === 'next') setIndex(index + 1);
        if (buttonClick === 'prev') setIndex(index - 1);
    }

    return (
        <Modal
            open={showWordContainer}
            onClose={() => setShowWordContainer(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                bgcolor: '#ccc',
                border: '3px solid #036f11',
                boxShadow: 24,
                p: 4,
                overflowY: 'auto',
                width: '20rem'
            }}>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            variant="span"
                            fontWeight={'300'}
                            fontSize={'0.6rem'}
                            align='right'
                            sx={{ textAlign: 'right', color: '#eee', cursor: 'pointer', backgroundColor: 'rgb(23, 38, 92, 0.8)', p: 1 }}
                            onClick={() => setShowWordContainer(false)}
                        >
                            Close X 
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            gutterBottom
                            variant="body1"
                            letterSpacing={2}
                            fontWeight={'700'}
                            fontSize={'3rem'}
                            align='justify'
                            sx={{ textAlign: 'center' }}
                        >
                            {englishWord}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            gutterBottom
                            variant="body1"
                            letterSpacing={2}
                            fontWeight={'700'}
                            fontSize={'3rem'}
                            align='justify'
                            sx={{ textAlign: 'center', mb: 4, color: '#E26310' }}
                        >
                            {nepaliWord}
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => handleClick('prev')} disabled={index === 0}
                            sx={{ backgroundColor: 'rgb(23, 38, 92, 0.8)', color: '#fff', p: 1, '&:hover': { backgroundColor: '#213785', color: '#ffffff'} }}>
                            <Typography
                                variant="span"
                                letterSpacing={2}
                                fontWeight={'300'}
                                fontSize={'0.6rem'}
                                sx={{ textAlign: 'left' }}
                            >
                                {prevText}
                            </Typography>
                        </Button>
                    </Grid>
                    <Grid item xs={6}>
                        <Button onClick={() => handleClick('next')} disabled={index === numberOfWords - 1}
                            sx={{ float: 'right', backgroundColor: 'rgb(23, 38, 92, 0.8)', color: '#fff', p: 1, '&:hover': { backgroundColor: '#213785', color: '#ffffff' } }}
                        >
                            <Typography
                                variant="span"
                                letterSpacing={2}
                                fontWeight={'300'}
                                fontSize={'0.6rem'}
                                sx={{ textAlign: 'right' }}
                            >
                                {nextText}
                            </Typography>
                        </Button>
                    </Grid>
                </Grid>
            </Box>
        </Modal>

    );
}
